import moment from "moment";
import React, { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useMyContext } from "../../Providers/SearchPatientProv";
import { formatTimestamp } from "../../services/utils.service";
import { FunnelIcon } from "@heroicons/react/24/solid";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentPatientId } from "../../features/patient/currentPatientSlice";
import { Button } from "@material-tailwind/react";
import {
  addPatientToWaitingList,
  getSpecialities,
} from "../../axiosAPI/Network";
import { RootState } from "../../store";
import { toast } from "react-hot-toast";
import useDynamicDialog from "../../hooks/useDynamicDialog";
import {
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Select,
  Option,
} from "@material-tailwind/react";
import { useGetSpecialityQuery } from "../../features/specialitys/specialityApi";
import DynamicDialogue from "../DynamicDialogue";
import { role } from "../../enums/app";
import { clearConsultationData } from "../../features/consultationData/consultationDataSlice";
import { clearMotifs } from "../../features/motifs/motifsSlice";
import { clearConsultationStore } from "../../clearConsultationstore";
type Props = {
  header: string[];
  data: any;
  className?: string;
};

const TableContainer = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const HeaderRow = styled.tr`
  background-color: #ffff;
  height: 55px;
  border-radius: 10.6765px;
`;

const HeaderCell = styled.th`
  padding: 8px;
`;

const DataRow = styled.tr<{ isEven: boolean }>`
  background-color: ${(props) => (props.isEven ? "#EFEFEF" : "#ffff")};
  height: 55px;
  border-radius: 16px;
  cursor: pointer;
  color: #858585;
`;

const DataCell = styled.td`
  padding: 8px;
  text-align: center;
`;

const Table: FC<Props> = ({ header, data, className }) => {
  const [dataTable, setDataTable] = useState<any>([]);
  const [rowToSort, setRowToSort] = useState<number>(0);
  const [dataRowProperties, setDataRowProperties] = useState([
    "cine",
    "firstName",
    "lastName",
    "gender",
    "birthDate",
    "medicalCover",
    "type",
    "updatedAt",
  ]);
  const { id: centerId } = useSelector((state: RootState) => state.auth.center);
  const storedUser = useSelector((state: RootState) => state.auth.user);

  const { patientdata }: any = useMyContext();
  const nav = useNavigate();
  const { openDynamicDialog, closeDynamicDialog } = useDynamicDialog();
  const dispatch = useDispatch();
  const [currentSortColumn, setCurrentSortColumn] = useState<string>(""); // Initialize with an empty string
  const [currentSortOrder, setCurrentSortOrder] = useState<string>("asc"); // Initialize with "asc"
  const [open, setOpen] = useState<boolean>(false);
  const [patientId, setPatientId] = useState<number>();
  const [specialityId, setSpecialityId] = useState<any>(1);
  const [specialitys, setSpecialitys] = useState<any>([]);
  const [submmitBtn, setSubmmitBtn] = useState<boolean>(false);
  function extractData(data: any) {
    let arr: any = [];
    if (data) {
      data.data?.results.forEach((el: any) => {
        const { type, cine, user, medicalCover, id, cineTutor } = el;
        const {
          firstName,
          originName,
          lastName,
          birthDate,
          gender,
          updatedAt,
        } = user;
        arr.push({
          id,
          type,
          originName,
          cine: cine || cineTutor,
          medicalCover,
          firstName,
          lastName,
          birthDate,
          gender,
          updatedAt,
        });
      });
    }
    setDataTable(arr);
  }

  // const { data: specialitys } = useGetSpecialityQuery('speciality')
  // console.log("🚀 ~ file: Table.tsx:116 ~ specialitys:", specialitys)

  useEffect(() => {
    getSpecialities().then((res) => {
      setSpecialitys(res?.data?.results);
    });
  }, []);

  function sortByProperty(prop: string) {
    const isCurrentAscending =
      prop === currentSortColumn && currentSortOrder === "asc";
    const newSortOrder = isCurrentAscending ? "desc" : "asc";

    const sortedArray = [...dataTable].sort((a, b) => {
      if (prop === "birthDate" || prop === "updatedAt") {
        return (
          moment(a[prop]).diff(moment(b[prop])) * (isCurrentAscending ? 1 : -1)
        );
      } else {
        return (
          (a[prop] < b[prop] ? -1 : a[prop] > b[prop] ? 1 : 0) *
          (isCurrentAscending ? 1 : -1)
        );
      }
    });

    setDataTable(sortedArray);
    setCurrentSortColumn(prop);
    setCurrentSortOrder(newSortOrder);
  }

  const addAdmission = (e: any) => {
    e.preventDefault();
    addPatientToWaitingList({
      patientId,
      centerId,
      specialityId: parseInt(specialityId),
    })
      .then((res) => {
        console.log(
          "🚀 ~ file: Table.tsx:140 ~ addPatientToWaitingList ~ res:",
          res
        );
        if (res?.status == 201) {
          toast.success(`patient ajouté à la liste d' attente`),
            setSubmmitBtn(true);
          setSpecialityId("");
          setOpen(false);
          setSubmmitBtn(false);
        }
      })
      .catch(() => {
        toast.error(`Une erreur est survenue`);
      });
  };
  useEffect(() => {
    extractData(data);
  }, [data]);

  useEffect(() => {
    if (patientId) {
      setOpen(true);
    }
  }, [patientId]);

  const handleOpen = () => setOpen(!open);

  const HandleSelectChange = (event: any) => {
    setSpecialityId(event.target.value);
  };

  // console.log(patientId)
  // console.log(typeof (parseInt(specialityId)))
  // console.log(specialityId)

  return (
    <div>
      <TableContainer>
        <thead>
          <HeaderRow className="text-[#5E5E5E]">
            {header.map((headerItem, key) => (
              <HeaderCell key={key}>
                <div className="flex gap-5 ml-4">
                  <p>{headerItem}</p>
                  <FunnelIcon
                    width={20}
                    className="cursor-pointer"
                    onClick={() => {
                      sortByProperty(dataRowProperties[key]);
                    }}
                  />
                </div>
              </HeaderCell>
            ))}
          </HeaderRow>
        </thead>
        {
          <tbody>
            {dataTable.length > 0 &&
              dataTable.map((dataRow: any, index: any) => (
                <DataRow
                  key={index}
                  isEven={index % 2 === 0}
                  onClick={() => {
                    clearConsultationStore(dispatch);
                    if (storedUser?.roles[0].name != role.HOST)
                      nav(`/doctor/patient/${dataRow.id}`);
                  }}
                >
                  <DataCell
                    className={dataRow.cine ? className : "text-red-700"}
                  >
                    {dataRow.cine ? dataRow.cine : "CIN non disponible"}
                  </DataCell>
                  <DataCell className={className}>{dataRow.firstName}</DataCell>
                  <DataCell className={className}>{dataRow.lastName}</DataCell>
                  <DataCell className={className}>
                    {dataRow.gender === "male" ? "M" : "F"}
                  </DataCell>
                  <DataCell className={className}>
                    {moment(dataRow.birthDate).format("DD/MM/YYYY")}
                  </DataCell>
                  <DataCell
                    className={
                      dataRow.medicalCover
                        ? className
                        : className + " text-red-700"
                    }
                  >
                    {dataRow.medicalCover
                      ? dataRow.medicalCover
                      : "Couverture non disponible"}
                  </DataCell>
                  {/* <DataCell className={className}>
                  {dataRow.type === "member" ? "OUI" : "NON"}
                </DataCell> */}
                  <DataCell className={className + "text-center"}>
                    {formatTimestamp(dataRow.updatedAt)}
                  </DataCell>
                  {storedUser?.roles[0].name == role.HOST && (
                    <DataCell className={className + "text-center"}>
                      <div className="flex gap-4">
                        <Button
                          onClick={() => setPatientId(dataRow.id)}
                          className="bg-green-400"
                        >
                          Ajouter à la liste d'attente
                        </Button>
                        <Button
                          onClick={() => {
                            clearConsultationStore(dispatch);
                            nav(`/doctor/patient/${dataRow.id}`);
                          }}
                          className="bg-blue-400"
                        >
                          Accéder au dossier patient
                        </Button>
                      </div>
                    </DataCell>
                  )}
                </DataRow>
              ))}
          </tbody>
        }
      </TableContainer>

      <DynamicDialogue
        handleOpen={handleOpen}
        open={open}
        specialityId={specialityId}
        HandleSelectChange={HandleSelectChange}
        specialitys={specialitys}
        submmitBtn={submmitBtn}
        myFunction={addAdmission}
      />
      {/* <Dialog open={open} handler={handleOpen}>
        <DialogHeader>Merci de choisir une spécialité</DialogHeader>
        <DialogBody>
          <div className="w-72">
            <select value={specialityId} onChange={HandleSelectChange} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
              <option value="">--</option>
               {specialitys?.map((item: any) =>
                <option value={item.id} >{item.name}</option>
              )} 
            </select>
            {!specialityId ? <span className="text-red-500">ce champ et obligatoire</span> : ""}
          </div>
        </DialogBody>
        <DialogFooter>
          <Button
            variant="text"
            onClick={handleOpen}
            className="mr-1 text-white bg-red-500"
          >
            <span>Annuler</span>
          </Button>
          <Button className="bg-[#1AA5C0]" disabled={submmitBtn} onClick={addAdmission}>
            <span>Confirmer</span>
          </Button>
        </DialogFooter>
      </Dialog> */}
    </div>
  );
};

export default Table;
