// currentPatientSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface Patient {
  
}

interface CurrentPatientState {
  currentPatient: Patient | null;
  currentPatientId: number | null;
}

const initialState: CurrentPatientState = {
  currentPatient: null,
  currentPatientId: null

};

const currentPatientSlice = createSlice({
  name: 'currentpatient',
  initialState,
  reducers: {
    setCurrentPatient: (state, action: PayloadAction<Patient | null>) => {
        state.currentPatient = action.payload;
    },
    setCurrentPatientId: (state, action: PayloadAction<number | null>) => {
        state.currentPatientId = action.payload;
      },
  },
});

export const { setCurrentPatient,setCurrentPatientId} = currentPatientSlice.actions;

export default currentPatientSlice.reducer;
