import { ErrorMessage, Formik, Field } from "formik";
import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";

import {
  addPatient,
  getLocation,
  getCountries,
  getCities,
  updatePatient,
  getRegions,
  getProvinces,
  getPatient,
} from "../../axiosAPI/Network";
import { useMutation } from "react-query";

import toast, { Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";

import moment from "moment";

import MySelect from "../addPatient/MySelect";
import * as Yup from "yup";
import {
  formatFirstName,
  formatLastName,
  generateUniqueId,
} from "../../../src/services/utils.service";
import MyInput from "../addPatient/MyInput";
// import { useQuery } from 'react-query'
// import axios from "axios";
// import { addPatient } from "../../axiosAPI/Network";

const StyledForm = styled.form`
  width: 100%;
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  overflow-y: auto;
  overflow-x: auto;
  max-height: 80vh;
  .myform {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 1rem;
    flex-wrap: wrap;
    .line {
      width: 100%;
      display: flex;
      gap: 1rem;
      flex-wrap: wrap;
      .duble-size {
        width: 100%;
      }
      .half-size {
        width: 50%;
      }
    }
  }
`;

const WrraperBotton = styled.div`
  margin-top: 1rem;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;
  color: #ffff;
  .but {
    background: #858585;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    border-radius: 3px;
    padding-inline: 4rem;
    padding-block: 0.5rem;
    margin-bottom: 1rem;
  }
`;

interface MyPropsDataType {
  ischild: boolean | undefined;
  iswife: boolean | undefined;
  isSearchAndAdd: boolean | undefined;
  setSearchAndAdd: any;
  setIsChild: any;
  isModif: boolean;
  setIswife: any;
  setIsModif: any;
  userSelected?: any;
  patientData?: any;
  setModal?: any;
  setSuccess?: any;
}

const UpdatePatient = ({
  ischild,
  setIsModif,
  iswife,
  setSearchAndAdd,
  isSearchAndAdd,
  isModif,
  setIsChild,
  setIswife,
  userSelected,
  patientData,
  setModal,
  setSuccess,
}: MyPropsDataType) => {
  const [dataToAdd, setDataToAdd] = useState<any>({});
  const [countryArr, setCountryArr] = useState<any>([]);
  const [provinceArr, setProvinceArr] = useState<any>([]);
  const [selectedProvinceId, setSelectedProvinceId] = useState<any>(null);

  const [regionArr, setRegionArr] = useState<any>([]);
  const [filteredCitiesArr, setFilteredCitiesArr] = useState<any>([
    {
      id: patientData?.cityId,
      name: patientData?.city?.name,
    },
  ]);

  const [cityArr, setCityArr] = useState<any>([]);
  const [citiesArr, setCitiesArr] = useState<any>([]);
  const [provincesArr, setProvincesArr] = useState<any>([]);
  const [stateObject, setStateObject] = useState<any>();
  const [age, setAge] = useState<any>(0);
  const requiredMsg: string = "Ce champs est obligatoire";
  const [selectCityValue, setSelectCityValue] = useState(patientData?.city?.id);
  const nav = useNavigate();
  useEffect(() => {
    console.log("userSelected", flattenObject(userSelected));

    (async () => {
      const [countriesData, citiesData, regionsData, provincesData] =
        await Promise.all([
          getCountries(),
          getCities(),
          getRegions(),
          getProvinces(),
        ]);
      console.log("cities", citiesData.data);

      setCitiesArr(citiesData.data.results);
      setProvincesArr(provincesData.data.results);
      setProvinceArr(
        provincesData.data.results.map((province: any) => province?.name)
      );
      setRegionArr(regionsData.data.results.map((region: any) => region.name));

      setCityArr(citiesData.data.results.map((city: any) => city.name));
      setCountryArr(
        countriesData.data.results.map((country: any) => country.name)
      );

      if (userSelected) setStateObject(flattenObject(userSelected));
    })();
  }, [userSelected]);

  useEffect(() => {
    const citySelect = document.getElementsByName("cityId")[0];

    setSelectedProvinceId(patientData.citry?.province?.id);
    console.log("changed province", selectedProvinceId);

    if (selectedProvinceId) {
      const provinceCities = citiesArr.filter(
        (city: any) => city.provinceId == selectedProvinceId
      );
      console.log("--->", provinceCities);
      provinceCities.unshift({
        id: "",
        name: "",
      });

      setFilteredCitiesArr(provinceCities);
    }
  }, [selectedProvinceId]);

  const { mutate, isLoading } = useMutation(
    (data: any) => updatePatient(data, isSearchAndAdd, patientData),
    {
      onSuccess: (res: any) => {
        if (res != false) {
          toast.success("Les modifications ont été bien enregistrées.", {
            id: generateUniqueId(),
          });
          setTimeout(() => {
            window.location.href = `/doctor/patient/${patientData?.id}`;
          }, 3000);

          console.log(res);
          setModal(false);
        } else {
          setModal(false);
          setTimeout(() => {
            setModal(true);
          }, 3000);
        }
      },
      onError: (err) => {
        console.log("errr", err);
        toast.error(
          "Une erreur s'est produite lors de la soumission du formulaire"
        );
      },
    }
  );

  /**
   *
   * @param obj
   * @returns
   */
  function flattenObject(obj: any) {
    let result: any = {};

    function recurse(current: any, path = "") {
      for (const key in current) {
        const value = current[key];
        const newPath = path ? `${key}` : key;

        if (typeof value === "object" && value !== null) {
          recurse(value, newPath);
        } else {
          result[newPath] = value;
        }
      }
    }

    recurse(obj);
    return result;
  }

  if (isLoading) return <div>Loading ...</div>;

  const selectedItem = provincesArr.find(
    (item: any) => item.id === patientData?.city?.province?.id
  );
  const result = provincesArr.filter(
    (item: any) => item.id === patientData?.city?.provinceId
  );

  const validationSchema = Yup.object().shape({
    cine: Yup.string().when(["cine_check", "birthDate"], {
      is: (cine_check: boolean, birthDate: Date) =>
        cine_check === false &&
        (moment().diff(moment(birthDate), "years") > 16 || birthDate == null),
      then: (schema) => schema.required(requiredMsg),
    }),
    cineTutor: Yup.string().when(["cine_check", "birthDate"], {
      is: (cine_check: boolean, birthDate: Date) =>
        cine_check == false && moment().diff(moment(birthDate), "years") <= 16,
      then: (schema) => schema.required(requiredMsg),
    }),
    lastName: Yup.string().required(requiredMsg),
    firstName: Yup.string().required(requiredMsg),
    gender: Yup.string().required(requiredMsg),
    birthDate: Yup.date()
      .required(requiredMsg)
      .max(new Date().toISOString().split("T")[0], "Date invalide"),
    address: Yup.string().required(requiredMsg),
    phoneNumber: Yup.string().when("phoneNumber_check", {
      is: (phoneNumber_check: boolean) => phoneNumber_check === false,
      then: (schema) =>
        schema
          .required(requiredMsg)
          .matches(
            /^(?:(?:\+|00)212|0)([5-7]\d{8})$/,
            "Format Numero de telephone incorrect"
          ),
    }),
    affiliationNumber: Yup.string().when("medicalCover", {
      is: (medicalCover: string) =>
        medicalCover !== "EXONÉRÉ" &&
        medicalCover !== "HORS COUVERTURE" &&
        medicalCover !== "",
      then: (schema) => schema.required(requiredMsg),
      otherwise: (schema) => schema.notRequired(),
    }),

    provinceId: Yup.string().required(requiredMsg),
    cityId: Yup.string().required(requiredMsg),
    medicalCover: Yup.string().required(requiredMsg),
  });

  return (
    <Formik
      initialValues={{
        cine: patientData.cine || "",
        cineTutor: patientData.cineTutor || "",
        cine_check: !(moment().diff(
          moment(patientData.user.birthDate),
          "years"
        ) <= 16
          ? patientData.cineTutor != null && patientData.cineTutor != ""
          : patientData.cine != null && patientData.cine != ""),
        firstName: patientData?.user?.firstName,
        lastName: patientData?.user?.lastName,
        gender: patientData?.user?.gender,
        birthDate: patientData?.user?.birthDate
          ? new Date(patientData.user.birthDate).toISOString().substring(0, 10)
          : "",
        phoneNumber: patientData?.user?.phoneNumber,
        phoneNumber_check: !(
          patientData?.user?.phoneNumber != "" &&
          patientData?.user?.phoneNumber != null
        ),
        cityId: selectCityValue,
        provinceId: patientData?.city?.provinceId,
        medicalCover: patientData.medicalCover,
        address: patientData?.user?.address,
        affiliationNumber: patientData.affiliationNumber,
        address2: patientData?.user?.address2,
      }}
      validationSchema={validationSchema}
      onSubmit={async (values) => {
        const { cine_check, phoneNumber_check, ...filteredValues } = values;
        const age = moment().diff(moment(filteredValues.birthDate), "years");
        if (age > 16 || filteredValues.birthDate === null) {
          filteredValues.cineTutor = "";
        } else {
          filteredValues.cine = "";
        }
        if (!ischild && !iswife && !isSearchAndAdd) {
          console.log("update values", filteredValues);
          mutate({ data: filteredValues });
        }
        if (isModif) {
          setIsModif(false);
        }
        if (isSearchAndAdd) {
          mutate({ data: { dataToAdd, filteredValues } });
          //setDataToAdd({ dataToAdd, filteredValues });
        }
        if (ischild || iswife) {
          setDataToAdd(values);
          setSearchAndAdd(true);
          setIsChild(false);
          setIswife(false);
        }
      }}
    >
      {({ values, handleChange, handleSubmit, setFieldValue }) => (
        <StyledForm onSubmit={handleSubmit}>
          <div className="myform">
            <div className="line">
              <div className="flex flex-col gap-4 relative">
                <Field
                  as={MyInput}
                  // ismodif={isModif}
                  label={
                    moment().diff(moment(values.birthDate), "years") > 16
                      ? "Nº CINE"
                      : "Nº CINE Tuteur"
                  }
                  placeholder={
                    moment().diff(moment(values.birthDate), "years") > 16
                      ? "Nº CINE"
                      : "Nº CINE Tuteur"
                  }
                  isImportant={true}
                  name={
                    moment().diff(moment(values.birthDate), "years") > 16
                      ? "cine"
                      : "cineTutor"
                  }
                  handleChange={(e: any) => {
                    handleChange(e);
                  }}
                  hasLeft={false}
                  hasRight={false}
                  type={"text"}
                  ismodif={!values.cine_check}
                />
                <ErrorMessage
                  className="absolute -bottom-[35px] error-message"
                  name={
                    moment().diff(moment(values.birthDate), "years") > 16
                      ? "cine"
                      : "cineTutor"
                  }
                  component="div"
                />
                <label className="flex justify-start items-center gap-4">
                  <Field
                    className=" m-0"
                    type={"checkbox"}
                    name={"cine_check"}
                    onChange={(e: any) => {
                      setFieldValue("cine_check", e.target.checked);
                      if (e.target.checked) {
                        if (
                          moment().diff(moment(values.birthDate), "years") > 16
                        ) {
                          setFieldValue("cine", "");
                        } else {
                          setFieldValue("cineTutor", "");
                        }
                      }
                    }}
                  />
                  {"Ne dispose pas de CINE"}
                </label>
              </div>
              <div className="flex flex-col relative">
                <Field
                  autoComplete="off"
                  as={MyInput}
                  ismodif={isModif}
                  label={"Nom"}
                  placeholder={"Nom"}
                  isImportant={true}
                  name={"lastName"}
                  handleChange={(e: any) => {
                    const formattedValue = formatLastName(e.target.value);
                    handleChange(e);
                    handleChange({
                      target: { name: "lastName", value: formattedValue },
                    });
                  }}
                  hasLeft={false}
                  hasRight={false}
                  type={"input"}
                />
                <ErrorMessage
                  className="absolute -bottom-[35px] error-message"
                  name="lastName"
                  component="div"
                />
              </div>
              <div className="flex flex-col relative">
                <Field
                  autoComplete="off"
                  as={MyInput}
                  ismodif={isModif}
                  label={"Prénom"}
                  placeholder={"Prénom"}
                  isImportant={true}
                  name={"firstName"}
                  handleChange={(e: any) => {
                    const formattedValue = formatFirstName(e.target.value);
                    handleChange(e);
                    handleChange({
                      target: { name: "firstName", value: formattedValue },
                    });
                  }}
                  hasLeft={false}
                  hasRight={false}
                  type={"input"}
                />
                <ErrorMessage
                  className="absolute -bottom-[35px] error-message"
                  name="firstName"
                  component="div"
                />
              </div>
              <div className="flex flex-col relative">
                <Field
                  as={MySelect}
                  ismodif={isModif}
                  label={"Sexe"}
                  placeholder={"Sexe"}
                  isImportant={true}
                  options={[
                    {
                      id: "",
                      name: "",
                    },
                    { id: "male", name: "Masculin" },
                    { id: "female", name: "Féminin" },
                  ]}
                  name={"gender"}
                  handleChange={handleChange}
                />
                <ErrorMessage
                  className="absolute -bottom-[35px] error-message"
                  name="gender"
                  component="div"
                />
              </div>
              <div className="flex flex-col relative">
                <Field
                  as={MyInput}
                  ismodif={isModif}
                  label={"Date de naissance"}
                  placeholder={"Date de naissance"}
                  isImportant={true}
                  name={"birthDate"}
                  handleChange={(e: any) => {
                    handleChange(e);
                    setFieldValue("birthDate", e.target.value);
                  }}
                  hasLeft={false}
                  hasRight={true}
                  type={"date"}
                  isAge={true}
                  maxDate={new Date()}
                />
                <ErrorMessage
                  className="absolute -bottom-[35px] error-message"
                  name="birthDate"
                  component="div"
                />
              </div>
              {!ischild && !iswife && (
                <div className="flex flex-col relative">
                  <Field
                    isImportant={true}
                    as={MySelect}
                    ismodif={isModif}
                    canBeEmpty={false}
                    label={"Couverture"}
                    placeholder={"Couverture"}
                    options={[
                      {
                        id: "",
                        name: "",
                      },
                      {
                        id: "HORS COUVERTURE",
                        name: "HORS COUVERTURE",
                      },
                      {
                        id: "CNSS PIPC",
                        name: "CNSS PIPC",
                      },
                      {
                        id: "EXONÉRÉ",
                        name: "EXONÉRÉ",
                      },
                      {
                        id: "CNSS",
                        name: "CNSS",
                      },
                      {
                        id: "CNOPS",
                        name: "CNOPS",
                      },
                      {
                        id: "FAR",
                        name: "FAR",
                      },
                    ]}
                    name={"medicalCover"}
                    handleChange={(e: any) => {
                      handleChange(e);
                      if (
                        e.target.value === "EXONÉRÉ" ||
                        e.target.value === "HORS COUVERTURE"
                      ) {
                        setFieldValue("affiliationNumber", "");
                      }
                    }}
                  />
                  <ErrorMessage
                    className="absolute -bottom-[35px] error-message"
                    name="medicalCover"
                    component="div"
                  />
                </div>
              )}

              {!ischild && !iswife && (
                <div className="flex flex-col relative">
                  <Field
                    as={MyInput}
                    // ismodif={isModif}
                    isImportant={
                      values.medicalCover !== "EXONÉRÉ" &&
                      values.medicalCover !== "HORS COUVERTURE" &&
                      values.medicalCover !== ""
                    }
                    label={"N adhérent"}
                    placeholder={"N adhérent"}
                    name={"affiliationNumber"}
                    handleChange={handleChange}
                    hasLeft={false}
                    hasRight={false}
                    type={"text"}
                    ismodif={
                      values.medicalCover !== "EXONÉRÉ" &&
                      values.medicalCover !== "HORS COUVERTURE" &&
                      values.medicalCover !== ""
                    }
                  />
                  <ErrorMessage
                    className="absolute -bottom-[35px] error-message"
                    name="affiliationNumber"
                    component="div"
                  />
                </div>
              )}

              <div className="flex flex-col relative">
                {!ischild && (
                  <Field
                    selected={patientData?.city?.province?.name}
                    value={selectedItem && selectedItem.name}
                    as={MySelect}
                    canBeEmpty={true}
                    ismodif={isModif}
                    label={"Province"}
                    placeholder={patientData?.city?.province?.name}
                    isImportant={true}
                    name={"provinceId"}
                    handleChange={(e: any) => {
                      setSelectedProvinceId(e.target.value);
                      handleChange(e);
                      handleChange({
                        target: { name: "cityId", value: "" },
                      });
                    }}
                    options={provincesArr}
                  />
                )}
                <ErrorMessage
                  className="absolute -bottom-[35px] error-message"
                  name="provinceId"
                  component="div"
                />
              </div>

              <div className="flex flex-col relative">
                <div className={`${ischild ? "half-size pl-7" : ""}`}>
                  <Field
                    as={MySelect}
                    selected={selectCityValue}
                    value={selectCityValue}
                    ismodif={isModif}
                    label={"Ville"}
                    canBeEmpty={false}
                    placeholder={"Ville"}
                    isImportant={true}
                    name={"cityId"}
                    handleChange={handleChange}
                    options={filteredCitiesArr}
                  />
                </div>
                <ErrorMessage
                  className="absolute -bottom-[35px] error-message"
                  name="cityId"
                  component="div"
                />
              </div>
              <div className="flex flex-col relative">
                <div className={`${ischild ? "duble-size" : ""}`}>
                  <Field
                    as={MyInput}
                    ismodif={isModif}
                    label={"Addresse"}
                    placeholder={"Addresse"}
                    isImportant={false}
                    name={"address"}
                    handleChange={(e: any) => {
                      handleChange(e);
                      handleChange({
                        target: { name: "address", value: e.target.value },
                      });
                    }}
                    hasLeft={false}
                    hasRight={false}
                    type={"text"}
                  />
                </div>
                <ErrorMessage
                  className="absolute -bottom-[35px] error-message"
                  name="address"
                  component="div"
                />
              </div>
              {!ischild && (
                <div className="flex flex-col relative">
                  <Field
                    as={MyInput}
                    ismodif={isModif}
                    label={"Complément d’adresse"}
                    placeholder={"Complément d’adresse"}
                    isImportant={false}
                    name={"address2"}
                    handleChange={(e: any) => {
                      handleChange(e);
                      handleChange({
                        target: { name: "address2", value: e.target.value },
                      });
                    }}
                    hasLeft={false}
                    hasRight={false}
                    type={"text"}
                  />
                  <ErrorMessage
                    className="absolute -bottom-[35px] error-message"
                    name="adress2"
                    component="div"
                  />
                </div>
              )}
              {!ischild && (
                <div className="flex flex-col gap-4 relative">
                  <Field
                    as={MyInput}
                    label={"Téléphone mobile"}
                    placeholder={"Téléphone mobile"}
                    isImportant={true}
                    name={"phoneNumber"}
                    handleChange={handleChange}
                    hasLeft={true}
                    hasRight={false}
                    type={"text"}
                    ismodif={!values.phoneNumber_check}
                  />
                  <ErrorMessage
                    className="absolute -bottom-[35px] error-message"
                    name="phoneNumber"
                    component="div"
                  />
                  <label className="flex justify-start items-center gap-4">
                    <Field
                      className=" m-0"
                      type={"checkbox"}
                      name={"phoneNumber_check"}
                      onChange={(e: any) => {
                        setFieldValue("phoneNumber_check", e.target.checked);
                        if (e.target.checked) {
                          setFieldValue("phoneNumber", "");
                        }
                      }}
                    />
                    {"Ne dispose pas de Téléphone"}
                  </label>
                </div>
              )}
            </div>
          </div>
          {isModif && (
            <WrraperBotton>
              <button
                type="button"
                className="but"
                onClick={() => setModal(false)}
              >
                Annuler
              </button>
              <button
                className="but"
                type="submit"
                // disabled
                style={{ background: "#2699DA" }}
              >
                Modifier
              </button>
            </WrraperBotton>
          )}
        </StyledForm>
      )}
    </Formik>
  );
};

export default UpdatePatient;
