import { Formik, Form, ErrorMessage, Field } from "formik";
import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import {
  addPatient,
  getLocation,
  getCountries,
  getCities,
  getRegions,
  getProvinces,
} from "../../axiosAPI/Network";
import { useMutation } from "react-query";
import InputWithButton from "./inputGenerator";
import toast, { Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";

import moment from "moment";
import ConsultationStatus from "../../Components/Steps/CustomComponents/ConsultationStatus";
import APP_CONSTANTS from "../../constants";
import { formatFirstName, formatLastName } from "../../services/utils.service";
import MyInput from "./MyInput";
import MySelect from "./MySelect";
import { RootState } from "src/store";
import { useSelector } from "react-redux";
// import { useQuery } from 'react-query'
// import axios from "axios";
// import { addPatient } from "../../axiosAPI/Network";

const StyledForm = styled.form`
  width: 100%;
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  .myform {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 1rem;
    flex-wrap: wrap;
    .line {
      width: 100%;
      display: flex;
      gap: 3rem;
      flex-wrap: wrap;
      .duble-size {
        width: 100%;
      }
      .half-size {
        width: 50%;
      }
    }
  }
`;

const WrraperBotton = styled.div`
  margin-top: 1rem;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;
  color: #ffff;
  .but {
    background: #858585;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    border-radius: 3px;
    padding-inline: 4rem;
    padding-block: 0.5rem;
    margin-bottom: 1rem;
  }
`;

interface MyPropsDataType {
  ischild: boolean | undefined;
  iswife: boolean | undefined;
  isSearchAndAdd: boolean | undefined;
  setSearchAndAdd: any;
  setIsChild: any;
  isModif: boolean;
  setIswife: any;
  setIsModif: any;
  userSelected?: any;
}

const AddPatientfrom = ({
  ischild,
  setIsModif,
  iswife,
  setSearchAndAdd,
  isSearchAndAdd,
  isModif,
  setIsChild,
  setIswife,
  userSelected,
}: MyPropsDataType) => {
  const [dataToAdd, setDataToAdd] = useState<any>({});
  const [countryArr, setCountryArr] = useState<any>([]);
  const [provinceArr, setProvinceArr] = useState<any>([]);
  const [selectedProvinceId, setSelectedProvinceId] = useState<any>(null);
  const [regionArr, setRegionArr] = useState<any>([]);
  const [cityArr, setCityArr] = useState<any>([]);
  const [filteredCitiesArr, setFilteredCitiesArr] = useState<any>([]);
  const [citiesArr, setCitiesArr] = useState<any>([]);
  const [provincesArr, setProvincesArr] = useState<any>([]);
  const [stateObject, setStateObject] = useState<any>();
  const [age, setAge] = useState<any>(0);
  const requiredMsg: string = "Ce champs est obligatoire";

  const currentCenter = useSelector((state: RootState) => state.auth.center);

  const nav = useNavigate();
  const validationSchema = Yup.object().shape({
    cine: Yup.string().when(["cine_check", "birthDate"], {
      is: (cine_check: boolean, birthDate: Date) =>
        cine_check === false &&
        (moment().diff(moment(birthDate), "years") > 16 || birthDate === null),
      then: (schema) => schema.required(requiredMsg),
    }),
    cineTutor: Yup.string().when(["cine_check", "birthDate"], {
      is: (cine_check: boolean, birthDate: Date) =>
        cine_check == false && moment().diff(moment(birthDate), "years") <= 16,
      then: (schema) => schema.required(requiredMsg),
    }),
    lastName: Yup.string().required(requiredMsg),
    firstName: Yup.string().required(requiredMsg),
    gender: Yup.string().required(requiredMsg),
    birthDate: Yup.date()
      .required(requiredMsg)
      .max(new Date().toISOString().split("T")[0], "Date invalide"),
    address: Yup.string().required(requiredMsg),
    phoneNumber: Yup.string().when("phoneNumber_check", {
      is: (phoneNumber_check: boolean) => phoneNumber_check === false,
      then: (schema) =>
        schema
          .required(requiredMsg)
          .matches(
            /^(?:(?:\+|00)212|0)([5-7]\d{8})$/,
            "Format Numero de telephone incorrect"
          ),
    }),
    affiliationNumber: Yup.string().when("medicalCover", {
      is: (medicalCover: string) =>
        medicalCover !== "EXONÉRÉ" &&
        medicalCover !== "HORS COUVERTURE" &&
        medicalCover !== "",
      then: (schema) => schema.required(requiredMsg),
      otherwise: (schema) => schema.notRequired(),
    }),
    provinceId: Yup.string().required(requiredMsg),
    cityId: Yup.string().required(requiredMsg),
    medicalCover: Yup.string().required(requiredMsg),
  });

  useEffect(() => {
    (async () => {
      const [countriesData, citiesData, regionsData, provincesData] =
        await Promise.all([
          getCountries(),
          getCities(),
          getRegions(),
          getProvinces(),
        ]);

      setCitiesArr(citiesData.data.results);
      setProvincesArr(provincesData.data.results);
      setProvinceArr(
        provincesData.data.results.map((province: any) => province?.name)
      );
      setRegionArr(regionsData.data.results.map((region: any) => region.name));

      setCityArr(citiesData.data.results.map((city: any) => city.name));
      setCountryArr(
        countriesData.data.results.map((country: any) => country.name)
      );

      if (userSelected) setStateObject(flattenObject(userSelected));
    })();
  }, [userSelected]);

  useEffect(() => {
    if (selectedProvinceId) {
      const citySelect = document.getElementsByName("cityId")[0];
      const provinceCities = citiesArr.filter(
        (city: any) => city.provinceId == selectedProvinceId
      );
      citySelect.nodeValue = "";
      setFilteredCitiesArr(provinceCities);
    }
  }, [selectedProvinceId]);

  const { mutate, isLoading } = useMutation(
    (data: any) => addPatient(data, isSearchAndAdd),
    {
      onSuccess: (res: any) => {
        // toast.success("Patient created successfully");
        // console.log("response is", res);
        if (res?.data?.id) nav(`/doctor/patient/${res?.data?.id}`);
      },
      onError: (err: any) => {
        // console.log("une erreur est survenue", err?.response?.data);
        //  toast.error("An error occured when submitting form");
      },
    }
  );

  /**
   *
   * @param obj
   * @returns
   */
  function flattenObject(obj: any) {
    let result: any = {};

    function recurse(current: any, path = "") {
      for (const key in current) {
        const value = current[key];
        const newPath = path ? `${key}` : key;

        if (typeof value === "object" && value !== null) {
          recurse(value, newPath);
        } else {
          result[newPath] = value;
        }
      }
    }

    recurse(obj);
    return result;
  }

  const handleCancelCick = () => {
    nav(APP_CONSTANTS.ROUTES.PATIENTS_LIST);
  };

  if (isLoading) return <div>Loading ...</div>;

  return (
    <>
      <Formik
        initialValues={{
          cine: "",
          cineTutor: "",
          cine_check: false,
          firstName: "",
          lastName: "",
          address: "",
          gender: null,
          birthDate: null,
          phoneNumber: "",
          phoneNumber_check: false,
          cityId: null,
          provinceId: null,
          affiliationNumber: "",
          medicalCover: "",
        }}
        validationSchema={validationSchema}
        onSubmit={async (values) => {
          const { cine_check, phoneNumber_check, ...filteredValues } = values;
          const age = moment().diff(moment(filteredValues.birthDate), "years");
          if (age > 16 || filteredValues.birthDate === null) {
            filteredValues.cineTutor = "";
          } else {
            filteredValues.cine = "";
          }
          console.log("sending data", filteredValues);
          if (!ischild && !iswife && !isSearchAndAdd)
            mutate({ data: filteredValues });

          if (isModif) {
            setIsModif(false);
          }
          if (isSearchAndAdd) {
            mutate({ data: { dataToAdd, values: filteredValues } });
          }

          if (ischild || iswife) {
            setDataToAdd(filteredValues);
            setSearchAndAdd(true);
            setIsChild(false);
            setIswife(false);
          }
        }}
      >
        {({ values, handleChange, handleSubmit, setFieldValue }) => (
          <Form className="addPatientForm" onSubmit={handleSubmit}>
            <div className="myform">
              <div className="line">
                <div className="flex flex-col gap-4 relative">
                  <Field
                    as={MyInput}
                    // ismodif={isModif}
                    label={
                      moment().diff(moment(values.birthDate), "years") > 16 ||
                      values.birthDate === null
                        ? "Nº CINE"
                        : "Nº CINE Tuteur"
                    }
                    placeholder={
                      moment().diff(moment(values.birthDate), "years") > 16 ||
                      values.birthDate === null
                        ? "Nº CINE"
                        : "Nº CINE Tuteur"
                    }
                    isImportant={true}
                    name={
                      moment().diff(moment(values.birthDate), "years") > 16 ||
                      values.birthDate === null
                        ? "cine"
                        : "cineTutor"
                    }
                    handleChange={(e: any) => {
                      handleChange(e);
                    }}
                    hasLeft={false}
                    hasRight={false}
                    type={"text"}
                    ismodif={!values.cine_check}
                  />
                  <ErrorMessage
                    className="absolute -bottom-[35px] error-message"
                    name={
                      moment().diff(moment(values.birthDate), "years") > 16 ||
                      values.birthDate === null
                        ? "cine"
                        : "cineTutor"
                    }
                    component="div"
                  />
                  <label className="flex justify-start items-center gap-4">
                    <Field
                      className=" m-0"
                      type={"checkbox"}
                      name={"cine_check"}
                      onChange={(e: any) => {
                        setFieldValue("cine_check", e.target.checked);
                        if (e.target.checked) {
                          const age = moment().diff(moment(values.birthDate), "years");
                          if (age > 16 || values.birthDate === null) {
                          setFieldValue("cine", "");
                          } else {
                          setFieldValue("cineTutor", "");
                          }
                        }
                      }}
                    />
                    {"Ne dispose pas de CINE"}
                  </label>
                </div>
                <div className="flex flex-col relative">
                  <Field
                    autoComplete="off"
                    as={MyInput}
                    ismodif={isModif}
                    label={"Nom"}
                    placeholder={"Nom"}
                    isImportant={true}
                    name={"lastName"}
                    handleChange={(e: any) => {
                      const formattedValue = formatLastName(e.target.value);
                      handleChange(e);
                      handleChange({
                        target: { name: "lastName", value: formattedValue },
                      });
                    }}
                    hasLeft={false}
                    hasRight={false}
                    type={"input"}
                  />
                  <ErrorMessage
                    className="absolute -bottom-[35px] error-message"
                    name="lastName"
                    component="div"
                  />
                </div>
                <div className="flex flex-col relative">
                  <Field
                    autoComplete="off"
                    as={MyInput}
                    ismodif={isModif}
                    label={"Prénom"}
                    placeholder={"Prénom"}
                    isImportant={true}
                    name={"firstName"}
                    handleChange={(e: any) => {
                      const formattedValue = formatFirstName(e.target.value);
                      handleChange(e);
                      handleChange({
                        target: { name: "firstName", value: formattedValue },
                      });
                    }}
                    hasLeft={false}
                    hasRight={false}
                    type={"input"}
                  />
                  <ErrorMessage
                    className="absolute -bottom-[35px] error-message"
                    name="firstName"
                    component="div"
                  />
                </div>
                <div className="flex flex-col relative">
                  <Field
                    as={MySelect}
                    ismodif={isModif}
                    label={"Sexe"}
                    placeholder={"Sexe"}
                    isImportant={true}
                    options={[
                      {
                        id: "",
                        name: "",
                      },
                      { id: "male", name: "Masculin" },
                      { id: "female", name: "Féminin" },
                    ]}
                    name={"gender"}
                    handleChange={handleChange}
                  />
                  <ErrorMessage
                    className="absolute -bottom-[35px] error-message"
                    name="gender"
                    component="div"
                  />
                </div>
                <div className="flex flex-col relative">
                  <Field
                    as={MyInput}
                    ismodif={isModif}
                    label={"Date de naissance"}
                    placeholder={"Date de naissance"}
                    isImportant={true}
                    name={"birthDate"}
                    handleChange={(e: any) => {
                      handleChange(e);
                    }}
                    hasLeft={false}
                    hasRight={true}
                    type={"date"}
                    isAge={true}
                    maxDate={new Date()}
                  />
                  <ErrorMessage
                    className="absolute -bottom-[35px] error-message"
                    name="birthDate"
                    component="div"
                  />
                </div>
                {!ischild && !iswife && (
                  <div className="flex flex-col relative">
                    <Field
                      isImportant={true}
                      as={MySelect}
                      ismodif={isModif}
                      canBeEmpty={false}
                      label={"Couverture"}
                      placeholder={"Couverture"}
                      options={[
                        {
                          id: "",
                          name: "",
                        },
                        {
                          id: "HORS COUVERTURE",
                          name: "HORS COUVERTURE",
                        },
                        {
                          id: "CNSS PIPC",
                          name: "CNSS PIPC",
                        },
                        {
                          id: "EXONÉRÉ",
                          name: "EXONÉRÉ",
                        },
                        {
                          id: "CNSS",
                          name: "CNSS",
                        },
                        {
                          id: "CNOPS",
                          name: "CNOPS",
                        },
                        {
                          id: "FAR",
                          name: "FAR",
                        },
                      ]}
                      name={"medicalCover"}
                      handleChange={(e: any) => {
                        handleChange(e);
                        if (
                          e.target.value === "EXONÉRÉ" ||
                          e.target.value === "HORS COUVERTURE" ||
                          e.target.value === ""
                        ) {
                          setFieldValue("affiliationNumber", "");
                        }
                      }}
                    />
                    <ErrorMessage
                      className="absolute -bottom-[35px] error-message"
                      name="medicalCover"
                      component="div"
                    />
                  </div>
                )}

                {!ischild && !iswife && (
                  <div className="flex flex-col relative">
                    <Field
                      as={MyInput}
                      // ismodif={isModif}
                      isImportant={
                        values.medicalCover !== "EXONÉRÉ" &&
                        values.medicalCover !== "HORS COUVERTURE" &&
                        values.medicalCover !== ""
                      }
                      label={"N adhérent"}
                      placeholder={"N adhérent"}
                      name={"affiliationNumber"}
                      handleChange={handleChange}
                      hasLeft={false}
                      hasRight={false}
                      type={"text"}
                      ismodif={
                        values.medicalCover !== "EXONÉRÉ" &&
                        values.medicalCover !== "HORS COUVERTURE" &&
                        values.medicalCover !== ""
                      }
                    />
                    <ErrorMessage
                      className="absolute -bottom-[35px] error-message"
                      name="affiliationNumber"
                      component="div"
                    />
                  </div>
                )}
                <div className="flex flex-col relative">
                  {!ischild && (
                    <Field
                      as={MySelect}
                      canBeEmpty={true}
                      ismodif={isModif}
                      label={"Province"}
                      placeholder={"Province"}
                      isImportant={true}
                      name={"provinceId"}
                      handleChange={(e: any) => {
                        setSelectedProvinceId(e.target.value);
                        handleChange(e);
                        handleChange({
                          target: { name: "cityId", value: "" },
                        });
                      }}
                      options={provincesArr}
                    />
                  )}
                  <ErrorMessage
                    className="absolute -bottom-[35px] error-message"
                    name="provinceId"
                    component="div"
                  />
                </div>

                <div className="flex flex-col relative">
                  <div className={`${ischild ? "half-size pl-7" : ""}`}>
                    <Field
                      as={MySelect}
                      ismodif={isModif}
                      label={"Ville"}
                      canBeEmpty={true}
                      placeholder={"Ville"}
                      isImportant={true}
                      name={"cityId"}
                      handleChange={handleChange}
                      options={filteredCitiesArr}
                    />
                  </div>
                  <ErrorMessage
                    className="absolute -bottom-[35px] error-message"
                    name="cityId"
                    component="div"
                  />
                </div>
                <div className="flex flex-col relative">
                  <div className={`${ischild ? "duble-size" : ""}`}>
                    <Field
                      as={MyInput}
                      ismodif={isModif}
                      label={"Addresse"}
                      placeholder={"Addresse"}
                      isImportant={true}
                      name={"address"}
                      handleChange={handleChange}
                      hasLeft={false}
                      hasRight={false}
                      type={"text"}
                    />
                  </div>
                  <ErrorMessage
                    className="absolute -bottom-[35px] error-message"
                    name="address"
                    component="div"
                  />
                </div>
                {!ischild && (
                  <div className="flex flex-col relative">
                    <Field
                      as={MyInput}
                      ismodif={isModif}
                      label={"Complément d’adresse"}
                      placeholder={"Complément d’adresse"}
                      isImportant={false}
                      name={"address2"}
                      handleChange={handleChange}
                      hasLeft={false}
                      hasRight={false}
                      type={"text"}
                    />
                    <ErrorMessage
                      className="absolute -bottom-[35px] error-message"
                      name="adress2"
                      component="div"
                    />
                  </div>
                )}
                {!ischild && (
                  <div className="flex flex-col gap-4 relative">
                    <Field
                      as={MyInput}
                      label={"Téléphone mobile"}
                      placeholder={"Téléphone mobile"}
                      isImportant={true}
                      name={"phoneNumber"}
                      handleChange={handleChange}
                      hasLeft={true}
                      hasRight={false}
                      type={"text"}
                      ismodif={!values.phoneNumber_check}
                    />
                    <ErrorMessage
                      className="absolute -bottom-[35px] error-message"
                      name="phoneNumber"
                      component="div"
                    />
                    <label className="flex justify-start items-center gap-4">
                      <Field
                        className=" m-0"
                        type={"checkbox"}
                        name={"phoneNumber_check"}
                        onChange={(e: any) => {
                          setFieldValue("phoneNumber_check", e.target.checked);
                          if (e.target.checked) {
                            setFieldValue("phoneNumber", "");
                          }
                        }}
                      />
                      {"Ne dispose pas de Téléphone"}
                    </label>
                  </div>
                )}
              </div>
            </div>
            {isModif && (
              <WrraperBotton>
                <button
                  type="button"
                  className="but"
                  onClick={handleCancelCick}
                >
                  Annuler
                </button>
                <button
                  className="but"
                  type="submit"
                  style={{ background: "#1AA5C0" }}
                >
                  {ischild || iswife ? "Suivant" : "Valider"}
                </button>
              </WrraperBotton>
            )}
          </Form>
        )}
      </Formik>
      <ConsultationStatus />
    </>
  );
};

export default AddPatientfrom;
