import { useEffect, useState, useMemo } from "react";
import { Outlet, useNavigate, useLocation, useParams } from "react-router-dom";
import Tabs from "../tabsComp";
import { GlobalStyle } from "../SearchPatient/SearchPatient";
import {
  Avatar,
  Button,
  Dialog,
  DialogBody,
  DialogFooter,
  DialogHeader,
  Spinner,
} from "@material-tailwind/react";
import { ReactComponent as DoctorSvg } from "../../assets/doctor.svg";
import { ReactComponent as WaitingListSvg } from "../../assets/waiting_list.svg";
import { ReactComponent as NurseSvg } from "../../assets/nurseHat.svg";
import Routes from "../../Routes";
import MyButton from "../common/Button";
import { set } from "lodash";
import {
  addPatientToWaitingList,
  createConsultation,
  getDoctorByCenterId,
  getPatientNamebyId,
  getSpecialities,
} from "../../axiosAPI/Network";
import { useQuery } from "react-query";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import {
  getLocationStatus,
  updateStatusConsultation,
} from "../../features/consultationStatus/consultationStatusSlice";
import { RootState } from "src/store";
import { CONSULTATION_STATUSES, CONSULTATION_TYPES } from "../../enums";
import { setConsultationMetadata } from "../../features/consultationMetadata";
import useDynamicDialog from "../../hooks/useDynamicDialog";
import APP_CONSTANTS, { PATIENT_RECORD_TABS } from "../../constants";
import AppointementModal from "../calendar/AppointementModal";
import { AppointementProvider } from "../calendar/AppointementContext";
import {
  setIsModalOpen,
  setPatientName,
} from "../../features/Appointment/appointmentSlice";
import { setCurretStep } from "../../features/currentStep/currentStepSlice";
import Dev from "../Dev";
import {
  useGetOnGoingPatientAndDoctorConsultationQuery,
  useGetOnGoingPatientConsultationQuery,
} from "../../features/consultationData/consultationDataApi";
import {
  clearConsultationData,
  setLastPatientOngoingConsultation,
} from "../../features/consultationData/consultationDataSlice";
import PatientFile from "../patientFile";
import MedicalHistory from "../medicalHistory";
import VitalSigns from "../vitalSigns";
import Biology from "../biology";
import Report from "../report";
import { PatientRecord } from "../patientRecord";
import { setIsLoading } from "../../features/dialog/dialogSlice";
import toast from "react-hot-toast";
import { useGetSpecialityQuery } from "../../features/specialitys/specialityApi";
import DynamicDialogue from "../DynamicDialogue";
import StartConsultationDialog from "../DynamicDialogue/StartConsultationDialog";
import { role } from "../../enums/app";
import { clear } from "console";
import { clearMotifs } from "../../features/motifs/motifsSlice";
import { clearConsultationStore } from "../../clearConsultationstore";
// make below component as a separate component

export default function Test() {
  const dispatch = useDispatch();
  const { confirm, message, title, openDynamicDialog, closeDynamicDialog } =
    useDynamicDialog();
  const consultationMetadata = useSelector(
    (state: RootState) => state.consultationMetadata
  );
  const {
    user: storedUser,
    box: currentBox,
    center: currentCenter,
  } = useSelector((state: RootState) => state?.auth);

  const params = useParams();
  const {
    data: patientOngoingConsultations,
    isLoading: isLoadingOngoingConsultations,
  } = useGetOnGoingPatientAndDoctorConsultationQuery({
    patientId: parseInt(params.id || "", 10),
    doctorId: storedUser?.doctor?.id,
  });

  const [index, setIndex] = useState(0);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [isLoadingConfirm, setIsLoadingConfirm] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const location = useLocation();
  const [patientId, setPatientId] = useState(0);
  const [doctorId, setDoctorId] = useState<any>();
  const [activeTab, setActiveTab] = useState<number>(0);
  const [openStartConsultationModal, setOpenStartConsultationModal] =
    useState(false);
  const [confirmed, setConfirmed] = useState(false);
  const [selectedConsultationType, setSelectedConsultationType] =
    useState<CONSULTATION_TYPES>(CONSULTATION_TYPES.FIRST_CONSULTATION);

  const [age, setAge] = useState<number>(0);
  const [ageText, setAgeText] = useState<string>("ans");
  const [specialityId, setSpecialityId] = useState<any>(1);
  const [submitBtn, setSubmitBtn] = useState<boolean>(false);
  const [submitStartConsultationBtn, setSubmitStartConsultationBtn] =
    useState<boolean>(false);
  const [specialitys, setSpecialitys] = useState<any>([]);

  const locationStatus = useSelector(
    (state: RootState) => state?.consultationStatus.locationStatus
  );
  const { data, isLoading, error } = useQuery(
    ["getPatientName", parseInt(params.id || "", 10)],
    () => getPatientNamebyId(+parseInt(params.id || "", 10))
  );
  useMemo(
    () =>
      dispatch(
        setPatientName(
          `${data?.user?.firstName || ""} ${data?.user?.lastName || ""}`
        )
      ),
    [data]
  );

  useEffect(() => {
    dispatch(updateStatusConsultation(CONSULTATION_STATUSES.ENDED));
  }, []);

  useEffect(() => {
    if (data) {
      const today = new Date();
      const birthDate = new Date(data.user.birthDate);
      const differ = moment(today).diff(moment(birthDate), "months");

      const ageInMonths =
        (today.getFullYear() - birthDate.getFullYear()) * 12 +
        (today.getMonth() - birthDate.getMonth());
      const ageInDays = Math.floor((today - birthDate) / (1000 * 60 * 60 * 24));
      const ageInYears = today.getFullYear() - birthDate.getFullYear();

      console.log("age in years", ageInYears);
      console.log("age in months", ageInMonths);
      console.log("age in days", ageInDays);

      if (ageInDays >= 0 && ageInDays < 30) {
        setAge(ageInDays);
        setAgeText("jours");
      }

      if (ageInMonths > 0 && ageInMonths < 12) {
        setAge(ageInMonths);
        setAgeText("mois");
      }

      if (ageInYears > 0 && ageInMonths >= 12) {
        setAge(ageInYears);
        setAgeText("ans");
      }
    }
  }, [data]);

  const consultationStatus = useSelector(
    (state: RootState) => state.consultationStatus.status
  );
  useEffect(() => {
    const lastPatientOngoingConsultation =
      patientOngoingConsultations?.results?.length > 0
        ? patientOngoingConsultations?.results[0]
        : null;
    dispatch(setLastPatientOngoingConsultation(lastPatientOngoingConsultation));

    if (lastPatientOngoingConsultation) {
      dispatch(
        getLocationStatus(
          `/doctor/steps/${parseInt(params.id || "", 10)}/${
            lastPatientOngoingConsultation?.id
          }`
        )
      );
      dispatch(updateStatusConsultation(CONSULTATION_STATUSES.ON_GOING));
    }
  }, [patientOngoingConsultations]);
  const status = useSelector((state: any) => state.consultationStatus.status);
  const patientOngoingConsultation = useSelector(
    (state: RootState) => state.consultationData.lastPatientOngoingConsultation
  );
  const { id: centerId } = useSelector((state: RootState) => state.auth.center);
  const { id: stationId } = useSelector((state: RootState) => state.auth.box);
  const currentPatient = useSelector(
    (state: RootState) => state.currentPatient.currentPatient
  );
  const [showResumeButton, setShowResumeButton] = useState(false);
  const [showButton, setShowButton] = useState(false);
  const [buttonText, setButtonText] = useState<string>("Chargement ...");

  useEffect(() => {
    console.log("ongoing", patientOngoingConsultation);
  }, [isLoadingOngoingConsultations]);

  useEffect(() => {
    // const isPatientFile = location.pathname.includes("doctor/patient-file");
    console.log(
      "getting consultation of",
      params?.id,
      patientOngoingConsultation
    );

    if (patientOngoingConsultation) {
      setShowButton(true);
      if (
        patientOngoingConsultation?.patientId === parseInt(params?.id || "") &&
        status === CONSULTATION_STATUSES.ON_GOING
      )
        setShowResumeButton(true);
    } else {
      setShowResumeButton(false);
      setShowButton(true);
    }
    console.log("setted show resume to", showResumeButton);
  }, [location, status, patientOngoingConsultation]);

  useEffect(() => {
    setPatientId(parseInt(params.id || "", 10));
    const path = location.pathname.split("/")[2];
    const index = Routes.findIndex((route) => route.path === `/${path}`);

    if (index !== -1) setIndex(index);
  }, [location]);

  useEffect(() => {
    clearConsultationStore(dispatch);
    if (showResumeButton) setButtonText("Reprendre la consultation locale");
    else setButtonText("Démarrer une consultation locale");
  }, [showResumeButton]);

  const handleOpen = () => {
    dispatch(setIsModalOpen(true));

    setOpen(!open);
  };

  useEffect(() => {
    console.log("open state", open);
  }, [open]);

  useEffect(() => {
    if (storedUser) {
      if (storedUser.roles[0].name == role.GENERALIST)
        setDoctorId(
          Number(
            window.localStorage.getItem(
              `${APP_CONSTANTS.LOCALSTORAGE_PREFIX}doctorId`
            )
          )
        );
      else if (
        storedUser.roles[0].name == role.NURSE &&
        storedUser?.center?.id
      ) {
        console.log("nurse request", storedUser);
        getDoctorByCenterId(storedUser?.center?.id)
          .then((res) => {
            console.log("center doctors", res.data.results[0].id);
            const id = res.data.results[0].doctor;
            setDoctorId(res.data.results[0].id);
          })
          .catch((err) => {
            console.log("error getting doctors");
          });
      }
    }
  }, [storedUser]);

  useEffect(() => {
    if (confirmed) startConsultation();
  }, [confirmed]);

  useEffect(() => {
    if (status === CONSULTATION_STATUSES.ON_GOING) setShowResumeButton(true);

    console.log("status of consultation is", status);
  }, [status]);

  const startConsultation = async () => {
    // const consultation = { patientId, doctorId: doctorId, isLocal: true };
    const consultation = {
      patient: { id: patientId },
      doctor: { id: doctorId },
      isLocal: true,
    };

    const res = await createConsultation(consultation);
    dispatch(updateStatusConsultation(CONSULTATION_STATUSES.ON_GOING));

    navigate(`/doctor/steps/${patientId}/${res.data.id}`);
  };

  // Handle the confirmation logic separately
  const handleConfirmAction = async () => {
    const consultation = {
      patient: { id: patientId },
      station: { id: currentBox.id },
      medicalActConsultations: [{ medicalActId: 93 }],
      type: selectedConsultationType,
      doctor: { id: doctorId },
      isLocal: true,
      stepsNumber: 8,
    };

    try {
      setIsLoadingConfirm(true);

      const res = await createConsultation(consultation);
      setIsLoadingConfirm(false);

      dispatch(updateStatusConsultation(CONSULTATION_STATUSES.ON_GOING));
      dispatch(
        setConsultationMetadata({
          patientId: parseInt(params.id || "", 10),
          consultationId: res.data.id,
        })
      );

      closeDynamicDialog();
      dispatch(setCurretStep(0));

      navigate(`/doctor/steps/${patientId}/${res.data.id}`);
    } catch (err) {
      console.log("error", err);
      if (err?.response?.status == 400)
        toast.error("Vous avez déja ouvert une consultation pour le patient");
      setIsLoadingConfirm(false);
      handleOpenStartConsultationModal();
      closeDynamicDialog();
    }
  };

  /**
   *
   */
  const handleStartConsultationClick = async () => {
    if (consultationStatus !== CONSULTATION_STATUSES.ON_GOING)
      setOpenStartConsultationModal(true);
    else {
      navigate(locationStatus);
    }
  };

  const handleTabChange = (index: number) => {
    setActiveTab(index);
    console.log("active tab is", index, currentPatient);
    setIndex(index);
    // navigate(
    //   `/doctor${Routes[index].path}/${location.pathname.split("/")[3]
    //   }`
    // );
  };
  const addAdmission = (e: any) => {
    e.preventDefault();
    addPatientToWaitingList({
      patientId: data.id,
      centerId,
      specialityId: parseInt(specialityId),
    })
      .then(() => {
        toast.success(`patient ajouté à la liste d' attente`);
        setSubmitBtn(true);
        setSpecialityId("");
        setOpenModal(false);
        setSubmitBtn(false);
      })
      .catch(() => {
        toast.error(`Une erreur est survenue`);
      });
  };

  const handleOpenModal = () => setOpenModal(!openModal);
  const handleOpenStartConsultationModal = () =>
    setOpenStartConsultationModal(!openStartConsultationModal);

  useEffect(() => {
    getSpecialities().then((res) => {
      setSpecialitys(res?.data?.results);
    });
  }, []);

  useEffect(() => {
    if (patientId) {
      setOpen(true);
    }
  }, [patientId]);

  const HandleSelectChange = (event: any) => {
    setSpecialityId(event.target.value);
  };
  const HandleSelectConsultationTypeChange = (event: any) => {
    setSelectedConsultationType(event.target.value);
  };

  return (
    <>
      <GlobalStyle>
        <div className="flex flex-col   items-center justify-start h-full px-4 py-2  ">
          <div className="header w-full bg-[#20A8D31C] rounded-lg px-4 py-5 border border-[#20A8D3] ">
            <div className="info flex justify-between items-center ">
              <div className="info flex items-center ">
                <Avatar
                  src="/user.svg"
                  alt="avatar"
                  variant="circular"
                  className="mr-5"
                />
                <div className="text flex flex-wrap">
                  <div className="name w-[50%] text-xl text-[#1AA5C0] font-[500]">
                    {`${data?.user?.firstName || ""}    ${
                      data?.user?.lastName || ""
                    }`}
                  </div>
                  <div className="birth w-[50%] text-[#1AA5C0] font-[500]">
                    Né(e) le{" "}
                    {moment(data?.user?.birthDate).format("DD/MM/YYYY")}
                  </div>
                  <div className="sexe w-[50%] text-[#5E5E5E]  font-[500]">
                    Sexe : {data?.user.gender == "male" ? "Homme" : "Femme"}
                  </div>
                  <div className="age w-[50%] text-[#1AA5C0] font-[500]">
                    {age} {ageText}
                  </div>
                </div>
              </div>
              <div className="buttons flex  gap-4 ">
                <>
                  <>
                    {storedUser.roles[0].name == role.HOST && (
                      <MyButton
                        text="Ajouter le patient à la file d'attente"
                        color="bg-green-500 text-white "
                        rounded={true}
                        onClick={() => setOpenModal(true)}
                      />
                    )}
                    {storedUser.roles[0].name != role.HOST && (
                      <>
                        <MyButton
                          text="Programmer un rendez-vous de télé-expertise"
                          color="bg-primary text-white "
                          icon={<DoctorSvg />}
                          rounded={true}
                          onClick={handleOpen}
                        />
                        {currentBox && currentCenter && showButton && (
                          <MyButton
                            style={{
                              background: !showResumeButton
                                ? "linear-gradient(90.84deg, 2.37%, #19C8D3 101.75%)"
                                : "linear-gradient(#fbd35c, #fcc573)",
                            }}
                            onClick={handleStartConsultationClick}
                            text={buttonText}
                            color="bg-secondary text-white"
                            icon={<NurseSvg />}
                            rounded={true}
                          />
                        )}
                        {!showButton && (
                          <MyButton
                            disabled
                            style={{
                              background: "gray",
                            }}
                            onClick={handleStartConsultationClick}
                            text={buttonText}
                            color=" text-white"
                            rounded={true}
                          />
                        )}
                      </>
                    )}
                  </>
                </>
              </div>
            </div>
          </div>

          {/* Patient Record */}
          <div className="my-2 w-full">
            <PatientRecord
              patientId={patientId}
              options={{
                enable:
                  storedUser?.roles[0].name == role.HOST
                    ? [PATIENT_RECORD_TABS.PATIENT_FILE]
                    : [
                        PATIENT_RECORD_TABS.PATIENT_FILE,
                        PATIENT_RECORD_TABS.MEDICAL_HISTORY,
                        PATIENT_RECORD_TABS.VITAL_SIGNS,
                        PATIENT_RECORD_TABS.BIOLOGY,
                        PATIENT_RECORD_TABS.REPORTS,
                      ],
              }}
            />
          </div>
          {/*  END patient record */}
        </div>
        <AppointementProvider>
            <AppointementModal
              open={open}
              patientId={patientId}
              handleOpen={handleOpen}
            />
        </AppointementProvider>
      </GlobalStyle>
      {/* appointment dialog */}
      <DynamicDialogue
        handleOpen={handleOpenModal}
        open={openModal}
        specialityId={specialityId}
        HandleSelectChange={HandleSelectChange}
        specialitys={specialitys}
        submmitBtn={submitBtn}
        myFunction={addAdmission}
      />

      {/* start consultation dialog dialog */}
      <StartConsultationDialog
        handleOpen={handleOpenStartConsultationModal}
        open={openStartConsultationModal}
        HandleSelectChange={HandleSelectConsultationTypeChange}
        submmitBtn={submitBtn}
        submitFunction={handleConfirmAction}
        isLoading={isLoadingConfirm}
      />
    </>
  );
}
