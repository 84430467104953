import React, { useEffect } from "react";
import styled from "styled-components";
import moment from "moment";
export const MyInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
  flex: 1;
  min-width: 300px;
`;

export const MyInputLabel = styled.label`
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 700;
  font-size: 15.0024px;
  line-height: 15px;
  color: #1aa5c0;
  margin-bottom: 5px;
  display: flex;
  gap: 4px;
  .req {
    align-self: center;
    color: #f90b33df;
  }
`;

export const MyInputstyle = styled.input`
  box-sizing: border-box;
  width: 100%;
  height: 50.26px;
  border: 1.67499px solid #858585;
  border-radius: 11.7249px;
  outline: none;
  transition: border-color 0.2s ease-in-out;
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 400;
  line-height: 15px;
  color: #5e5e5e;
  padding: 0.5rem;
  font-size: 1rem;
  color: #333;
  &:focus {
    border-color: rgba(31, 131, 131, 1);
  }

  background-color: ${(props) => (!props.disabled ? "#ffffff" : "#f1f1f1")};
`;

export const InputWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 1rem;
`;

export const SideDiv = styled.div`
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 500;
  font-size: 17.6551px;
  line-height: 15px;
  text-align: center;
  background: #1aa5c0;
  border-radius: 11.7249px;
  color: #ffffff;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-inline: 1.5rem;
  white-space: nowrap;
`;
export interface MyInputProps {
  label: string;
  placeholder: string;
  isImportant: boolean;
  ismodif: boolean;
  name: string;
  handleChange: any;
  hasLeft: boolean;
  hasRight: boolean;
  type: string;
  isAge?: boolean;
  value?: any;
  pattern?: string;
}

const MyInput = ({
  ismodif,
  label,
  placeholder,
  isImportant,
  name,
  handleChange,
  hasLeft,
  hasRight,
  type,
  pattern,
  isAge,
  value,
}: MyInputProps) => {
  const [age, setAge] = React.useState<any>(null);
  const [text, setText] = React.useState<string>("ans");

  useEffect(() => {
    if (value) handleBirthDate(value);
  }, [value]);

  const onChange = (e: any) => {
    handleChange(e);
    handleBirthDate(e.target.value);
  };

  const handleBirthDate = (date: any) => {
    const today = new Date();
    const birthDate = new Date(date);
    const ageInMonths =
      (today.getFullYear() - birthDate.getFullYear()) * 12 +
      (today.getMonth() - birthDate.getMonth());
    const ageInDays = Math.floor((today - birthDate) / (1000 * 60 * 60 * 24));
    const ageInYears = today.getFullYear() - birthDate.getFullYear();

    if (ageInDays >= 0 && ageInDays < 30) {
      setAge(ageInDays);
      setText("jours");
    }

    if (ageInMonths > 0 && ageInMonths < 12) {
      setAge(ageInMonths);
      setText("mois");
    }

    if (ageInYears > 0 && ageInMonths >= 12) {
      setAge(ageInYears);
      setText("ans");
    }
  };

  useEffect(() => {
    if (age && age < 1) setText("mois");
  }, [age]);

  return (
    <MyInputContainer>
      <MyInputLabel>
        {label} {isImportant && <div className="req">*</div>}
      </MyInputLabel>
      <InputWrapper>
        <MyInputstyle
          autoComplete="off"
          disabled={!ismodif}
          placeholder={placeholder}
          // pattern={pattern}
          name={name}
          onChange={isAge ? onChange : handleChange}
          type={type}
          value={value}
          max={isAge ? moment().format("YYYY-MM-DD") : undefined}
        />
        {hasRight && <SideDiv>{`${age ? age : "--"} ${text}`}</SideDiv>}
      </InputWrapper>
    </MyInputContainer>
  );
};

export default MyInput;
