import { useEffect, useMemo, useState } from "react";
import AddPatientfrom from "../addPatient/addPatientfrom";
import { ReactComponent as ModifyIcon } from "../../assets/modify.svg";
import styled from "styled-components";
import { Button, Spinner } from "@material-tailwind/react";
import MyModalInput from "../addPatient/Modal";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { getPatient } from "../../axiosAPI/medical-history";
import moment from "moment";
import toast, { Toaster } from "react-hot-toast";
import Table from "./Table";
import UpdateModal from "./Modal";
import { UserPlusIcon } from "@heroicons/react/24/solid";
import { setCurrentPatient } from "../../features/patient/currentPatientSlice";
import { useDispatch, useSelector } from "react-redux";
import { IPatient } from "../../interfaces/patient";
import { RootState } from "../../store";
import { role } from "../../enums/app";

interface IPatientFileProps {
  patientId: number;
}
const PatientFile = (props: IPatientFileProps) => {
  const { id } = useParams();
  const [isModif, setIsModif] = useState(false);
  const [visible, setVisible] = useState(false);
  const [updateVisible, setUpdateVisible] = useState(false);
  const [patientId, setPatientId] = useState<number>();
  const [success, setSuccess] = useState(false);
  const [data, setData] = useState<IPatient>();
  const params = useParams();
  const dispatch = useDispatch();
  const storedUser = useSelector((state: RootState) => state.auth.user);
  const [isLoadingData, setIsLoadingData] = useState(false);
  useEffect(() => {
    getPatient(props.patientId != 0 ? Number(props.patientId) : Number(id))
      .then((res) => {
        console.log(res);

        setData(res);
        console.log("data", res);
      })
      .catch((err) => {});
  }, []);

  // const { data, isLoading, error, refetch } = useQuery(
  //   ["getPatient", props.patientId],
  //   () => getPatient(+props.patientId)
  // );
  useEffect(() => {
    if (data) setIsLoadingData(true);
  }, [data]);

  if (data) {
    dispatch(setCurrentPatient(data));
  }
  useEffect(() => {
    // refetch();
    setPatientId(props.patientId);
  }, []);
  const adherants = useMemo(() => {
    console.log(data?.haveRights);

    if (!data?.haveRights) return [];
    return data?.haveRights?.map((x: any) => ({
      id: x?.id,
      ayant: x?.type,
      surname: x?.user?.lastName,
      name: x?.user?.firstName,
      gender: x?.user?.gender == "male" ? "Homme" : "Femme",
    }));
  }, [data]);

  useEffect(() => {
    if (success) {
      // notify();
      refetch();
    }
  }, [success]);
  const notify = () => toast.success("Adhérent created successfully");

  return (
    <GlobalContent className=" h-full w-full flex-col flex">
      {/* < Toaster /> */}
      <div className="bar">
        <div className="title">
          Patient{" "}
          <span className="ad">
            ({data?.type == "child" ? "Ayant droit" : "Adhérent"})
          </span>
        </div>
        <div className="bottons">
          {/* {data?.type != "child" && (
            <Button className="droit" onClick={() => setVisible(true)}>
              <UserPlusIcon className="w-6" />
              <div>Ajouter ayant droit</div>
            </Button>
          )} */}
          {storedUser?.roles[0].name != role.SPECIALIST && (
            <Button className="modify" onClick={() => setUpdateVisible(true)}>
              <ModifyIcon />
              <div>Modifier la fiche</div>
            </Button>
          )}
        </div>
      </div>
      <MyModalInput
        setSuccess={setSuccess}
        visible={visible}
        setVisible={setVisible}
        patientCine={data?.cine}
      />
      <UpdateModal
        data={data}
        setSuccess={setSuccess}
        visible={updateVisible}
        setVisible={setUpdateVisible}
        patientCine={data?.cine}
      />

      {/* CINE */}
      {isLoadingData ? (
        <div className="flex flex-col justify-between  h-full py-5 px-8">
          <div className=" flex border-b-2 py-4 ">
            <div className="w-full flex ">
              <div className="flex flex-col gap-5 flex-1 ">
                <span className="text-[#1AA5C0] text-[19.51px]">
                  {moment().diff(moment(data?.user?.birthDate), "years") > 16
                    ? "N°CINE"
                    : "N°CINE Tuteur"}
                </span>
                <span className="text-[#5E5E5E] text-[22.76px]">
                  {moment().diff(moment(data?.user?.birthDate), "years") > 16
                    ? data?.cine
                    : data?.cineTutor}
                </span>
              </div>
              <div className="flex flex-col gap-5 flex-1">
                <span className="text-[#1AA5C0] text-[19.51px]">Nom</span>
                <span className="text-[#5E5E5E] text-[22.76px]">
                  {data?.user?.lastName}
                </span>
              </div>
              <div className="flex flex-col gap-5 flex-1">
                <span className="text-[#1AA5C0] text-[19.51px]">Prénom</span>
                <span className="text-[#5E5E5E] text-[22.76px]">
                  {data?.user?.firstName}
                </span>
              </div>
              <div className="flex flex-col gap-5 flex-1">
                <span className="text-[#1AA5C0] text-[19.51px]">Sexe</span>
                <span className="text-[#5E5E5E] text-[22.76px]">
                  {data?.user?.gender == "male" ? "Homme" : "Femme"}
                </span>
              </div>
              <div className="flex flex-col gap-5 flex-1">
                <span className="text-[#1AA5C0] text-[19.51px]">
                  Date de naissance
                </span>
                <span className="text-[#5E5E5E] text-[22.76px]">
                  {moment(data?.user?.birthDate).format("DD/MM/YYYY")}
                </span>
              </div>
            </div>
          </div>
          {/* Couverture* */}

          <div className=" flex border-b-2 py-4">
            <div className="w-full flex ">
              <div className="flex flex-col gap-5 flex-1">
                <span className="text-[#1AA5C0] text-[19.51px]">
                  Couverture
                </span>
                <span className="text-[#5E5E5E] text-[22.76px]">
                  {data?.medicalCover || "-"}
                </span>
              </div>
              <div className="flex flex-col gap-5 flex-1">
                <span className="text-[#1AA5C0] text-[19.51px]">
                  Nº adhérent
                </span>
                <span className="text-[#5E5E5E] text-[22.76px]">
                  {data?.affiliationNumber}
                </span>
              </div>
              <div className="flex flex-col gap-5 flex-1">
                <span className="text-[#1AA5C0] text-[19.51px]">IPP</span>
                <span className="text-[#5E5E5E] text-[22.76px]">
                  {data?.ipp}
                </span>
              </div>

              <div className="flex flex-col gap-5 flex-1">
                <span className="text-[#1AA5C0] text-[19.51px]">Ville</span>
                <span className="text-[#5E5E5E] text-[22.76px]">
                  {data?.city?.name || "-"}
                </span>
              </div>
              <div className="flex flex-col gap-5 flex-1">
                <span className="text-[#1AA5C0] text-[19.51px]">Province</span>
                <span className="text-[#5E5E5E] text-[22.76px]">
                  {data?.city?.province?.name || "-"}
                </span>
              </div>
            </div>
          </div>
          {/* Adresse */}

          <div className=" flex border-b-2 py-4  ">
            <div className="w-full flex justify-start ">
              <div className="flex flex-col gap-5   w-[39.5rem]">
                <span className="text-[#1AA5C0] text-[19.51px]">Adresse</span>
                <span className="text-[#5E5E5E] text-[22.76px]">
                  {data?.user?.address || "-"}
                </span>
              </div>
              <div className="flex flex-col gap-5 w-[39.5rem]">
                <span className="text-[#1AA5C0] text-[19.51px]">
                  Complément d’adresse
                </span>
                <span className="text-[#5E5E5E] text-[22.76px]">
                  {" "}
                  {data?.user?.address2 || "-"}
                </span>
              </div>
              <div className="flex flex-col gap-5   w-[39.5rem]">
                <span className="text-[#1AA5C0] text-[19.51px]">
                  Téléphone mobile
                </span>
                <span className="text-[#5E5E5E] text-[22.76px]">
                  {data?.user?.phoneNumber || "-"}
                </span>
              </div>
              {/* <div className="flex flex-col gap-5 flex-1">
              <span className="text-[#1AA5C0] text-[19.51px]">
              Code postal
              </span>
              <span className="text-[#5E5E5E] text-[22.76px]">
                {data?.user?.postalCode || "-"}
              </span>
            </div> */}
            </div>
          </div>
          {/* Telephone mobile* */}
          {/* <div className=" flex border-b-2 py-4  ">
          <div className="w-full flex justify-start ">
         
            <div className="flex flex-col gap-5 w-[39.5rem]">
              <span className="text-[#1AA5C0] text-[19.51px]">
                Téléphone fixe
              </span>
              <span className="text-[#5E5E5E] text-[22.76px]">
                {data?.user?.phoneNumber2 || "-"}
              </span>
            </div>
            <div className="flex flex-col gap-5 flex-1">
              <span className="text-[#1AA5C0] text-[19.51px]">
                Adresse électronique
              </span>
              <span className="text-[#5E5E5E] text-[22.76px]">
                {data?.user?.email || "-"}
              </span>
            </div> 
          </div>
        </div> */}
        </div>
      ) : (
        <div className="flex justify-center self-center w-[100%] mt-12">
          <Spinner />
        </div>
      )}

      {/* <AddPatientfrom
        isModif={isModif}
        setIsModif={setIsModif}
        ischild={false}
        iswife={false}
        setIsChild={() => {}}
        setIswife={() => {}}
        isSearchAndAdd={false}
        setSearchAndAdd={() => {}}
      /> */}
      {adherants.length ? (
        <>
          <div className="text-[#20A8D3] mb-5 text-[25px]">Ayants droit</div>
          <div>
            <Table
              rows={adherants}
              header={["Ayant droit", "Nom", "Prénom", "Sexe", "Actions"]}
            />
          </div>
        </>
      ) : null}
    </GlobalContent>
  );
};

export default PatientFile;

const GlobalContent = styled.div`
  margin-top: 20px;
  .bar {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .title {
      font-style: normal;
      font-weight: 500;
      font-size: 23.2777px;
      line-height: 15px;
      /* identical to box height, or 44% */

      display: flex;
      align-items: flex-end;

      color: #20a8d3;
      .ad {
        margin-left: 1rem;
        font-weight: 300;
        font-size: 17.2777px;
        color: #858585;
      }
    }
    .bottons {
      display: flex;
      align-items: center;
      gap: 1rem;
    }
    .modify {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 10px;
      background: #335d84;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
      border-radius: 10px;
      width: fit-content;
      padding-inline: 20px;
      padding-block: 10px;
      font-style: normal;
      font-weight: 500;
      font-size: 17px;
      line-height: 13px;
      color: #ffffff;
      margin-bottom: 20px;
    }
    .droit {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 10px;
      background: #20a8d3;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
      border-radius: 10px;
      width: fit-content;
      padding-inline: 20px;
      padding-block: 10px;
      font-style: normal;
      font-weight: 500;
      font-size: 17px;
      line-height: 13px;
      color: #ffffff;
      margin-bottom: 20px;
    }
  }
`;
